.fieldLabel {
    display: flex;
    font-size: 120%;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 0.5em;
    color: var(--boldtext);
    /* gap: 5px; */
}

.smallTextInput {
    font-family: 'Roboto', sans-serif;
    border: 2px solid gray;
    padding: 5px;
    margin-right: 10px;
    background-color: var(--background);
    font-size: 16px;
}

.smallTextInput {
    border-radius: 20px;
}

.addButton {
    background-color: #3dc45a;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    border: 0px;
    margin: 5px 10px 5px 0px;
    cursor: pointer;
}

.addButton:disabled {
    background-color: var(--gray);
    cursor: default;
}

.removeButton {
    border-radius: 5px;
    border: 0px;
    cursor: pointer;
    background-color: transparent;
    width: 1.5em;
    height: 1.5em;
    background-image: url("../../assets/X.png");
    background-position: 50% 50%;
    background-size: 2em;
    background-repeat: no-repeat;
}

.inputWrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}
.suggestionList {
    position: absolute;
    /* margin-top: 85px; */
    top: 100%;
    left: 0;
    background: var(--background);
    border: 1px solid var(--text);
    width: 300px;
    list-style-type: none;
    padding: 0;
    max-height: 350px;
    /* overflow-y: auto; */
    z-index: 1000;
    border-radius: 5px;
}

.suggestionItem {
    padding: 8px;
    cursor: pointer;
}

.tagInstructions{
    padding: 8px;
    font-style: italic;
    color: var(--outline);
    border-top: 1px solid var(--outline);
    font-size: 85%;
} 

.suggestionItem:hover {
    background: var(--gray);
}

.tag {
    color:white;
    background-color: var(--themeColor);
    border-radius: 15px;
    padding: 0.4em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    width: fit-content;
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap:10px;
    margin-bottom: 1em;
    margin-top: 0.5em;
}