@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

.settingsForm {
    display: flex;
    justify-content: center;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 50vw;
    min-width: 350px;
}

.settingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid var(--gray);
    border-radius: 5px;
}

.settingsTitle {
    color: var(--boldtext);
    justify-content: center;
    font-size: 250%;
    border-bottom: .25rem solid var(--themeColor);
}

.settingTitle {
    color: var(--text);
    margin-right: auto;
    font-family: "Roboto", serif;
    font-size: 150%;
    font-weight: bold;
}

.switch {
    margin-left: auto;
    position: relative;
    display: inline-block;
    width: 60px;
    min-width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.roundSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.roundSlider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--background);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.roundSlider {
    background-color: var(--themeColor);
}

input:focus+.roundSlider {
    box-shadow: 0 0 1px var(--themeColor);
}

input:checked+.roundSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.submitButton {
    font-family: 'Roboto', 'sans-serif';
    font-weight: bolder;
    font-size: 125%;
    color: var(--boldtext);
    background-color: var(--gray);
    border: 0px;
    border-radius: 5px;
    height: 100%;
    padding: 10px;
    cursor: pointer;
}

/* Stole some of Syd's code for making the select box look good */
.settingsSelect {
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    position: relative;

    border: 1px solid var(--themeColor);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;

    font-size: 15px;
    cursor: pointer;
    line-height: 1.1;

    background-color: var(--background);
    color: var(--text);
}