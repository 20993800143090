.pageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.aboutUs {
    max-width: 80%;
    align-self: center;
}

.pageContainer h1 {
    color: var(--boldtext);
}
.aboutUs p {
    font-size: 125%;
    line-height: 1.5;
    color: var(--boldtext);
}

.image {
    width: 100%;
    height: auto;
}

.headshot {
    min-width: 15vw;
    max-width: 15vw;
    min-height: 15vw;
    max-height: 15vw;
    border-radius: 50%;
    align-self: center;
    object-fit: cover;
}

.bioSection{
    display: flex;
    flex-direction: row;
    gap: 2em;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.bioSection p {
    line-height: 1.5;
    color: var(--boldtext);
    text-align: justify;
}

.bioSection h2 {
    color: var(--themeColor);
    font-size: 1.75em;
}

.teamMembers {
    width: 80%;
}

.screenshot {
    width: 80%;
    height: auto;
    border-style: solid;
    border-width: 4px;
    border-color: var(--text);
    align-self: center;
}

.tutorial {
    width: 80%;
    display: flex;
    flex-direction: column;
}