.profilePicture {
    width: 40px;
    height: 40px;
    align-self: center;
    object-fit: cover;
    border-radius: 50%;
}

.memberInfo a {
    font-weight: bold;
    font-size: 1.17em;
    color: var(--boldtext);
    text-decoration: none;
    text-wrap: nowrap;
}

.memberInfo a:hover {
    color: var(--themeColor);
}

.memberInfo {
    display: flex;
    flex-direction: row;
    gap: 1em;
    margin-top: 0.7em;
}

.memberDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0px;
}

.memberDetails > p {
    margin-block-start: 0;
    margin-block-end: 0;
}

.notifs {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.notification {
    border: solid;
    border-width: 2px;
    border-color: var(--outline);
    border-radius: 5px;
    padding: 16px;
}

.top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
    justify-content: space-between;
    position: relative;
}

.top > p {
    color: var(--text);
    margin-top: 0.5em;
    position: absolute;
    top: 0;
    right: 0;
}

.bottom {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    justify-content: space-between;
}

h4 {
    font-size: 16px;
    font-weight: normal;
    color: var(--text);
}

a {
    font-weight: bold;
    font-size: 1em;
    color: var(--themeColor);
    text-decoration: none;
}

a:hover {
    color: black;
}

.button {
    border: none;
    background-color: transparent;
    border-radius: 5px;
    color: var(--text);
}

.button:hover {
    background-color: var(--gray);
}